import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Grid, CircularProgress, colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBarComponent from '../components/AppBar';
import { useStores } from '../hooks/useStores';
import useTeamQuery from '../hooks/useTeamQuery';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
    minHeight: '100vh'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  text: {
    color: theme.palette.text.primary,
  },
  yellowText: {
    color: colors.yellow[800],
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  bottomNavContainer: {
    position: 'sticky',
    bottom: 0,
    paddingBottom: 'env(safe-area-inset-bottom, 20px)',
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  divider: {
    backgroundColor: theme.palette.text.primary
  }
}));

const HeadToHeadPage = () => {
  const { teamId, opponentName } = useParams()
  const classes = useStyles();
  const navigate = useNavigate();

  const { data: team, isLoading } = useTeamQuery(teamId)

  const record = team?.getRecordAgainstTeam(opponentName!)

  const H2H = () => {
    if (!record) {
      return <Typography variant="h6" className={classes.primaryText}>{`No games played against ${opponentName}`}</Typography>
    }

    return (
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontSize: 24 }} className={classes.primaryText}>{`Head to head vs. ${opponentName}`}</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Wins: ${record?.wins}`}</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Losses: ${record?.losses}`}</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Points for: ${record?.scoreFor}`}</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Points against: ${record?.scoreAgainst}`}</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Average point win margin: ${record?.averageWinDifference}`}</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Average point loss margin: ${record?.averageLossDifference}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <AppBarComponent
        LeftIcon={<ArrowBackIcon />}
        onLeftIconClick={() => navigate(-1)}
      >
        {team?.team.teamName ?? 'Loading...'}
      </AppBarComponent>
      <main className={classes.content}>
        {isLoading
          ? <Grid item xs={12}><CircularProgress /></Grid>
          : <H2H />
        }
      </main>
    </div>
  );
};

export default observer(HeadToHeadPage);
