import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree';
import { getTeamLadder, getTeamFixture, getTeamResults, IGetTeamFixtureReturnValue, IGetTeamLadderReturnValue, IGetTeamResultsReturnValue, ISearchTeamsReturnValue } from "../helpers/queries"
import moment from "moment-timezone"
import { EventAttributes } from "ics"

const TeamStore = types
  .model('Team', {
    team: types.frozen<ISearchTeamsReturnValue[0]>(),
    ladder: types.optional(types.frozen<IGetTeamLadderReturnValue | null>(), null),
    results: types.optional(types.frozen<IGetTeamResultsReturnValue | null>(), null),
    fixture: types.optional(types.frozen<IGetTeamFixtureReturnValue | null>(), null)
  })
  .actions((self: any) => {
    const fetchLadder = flow(function* () {
      self.ladder = yield getTeamLadder(self.team.teamId, self.team.stadiumId, self.team.gradeId, self.team.sportId);
    })

    const fetchResults = flow(function* () {
      self.results = yield getTeamResults(self.team.teamId, self.team.stadiumId, self.team.gradeId, self.team.sportId);
    })

    const fetchFixture = flow(function* () {
      const fetchedFixture = yield getTeamFixture(self.team.teamId, self.team.stadiumId, self.team.gradeId, self.team.sportId);
      self.fixture = self.sortFixture(fetchedFixture);
    })

    const getStreak = () => {
      // Sort the results by date played and count 'WOF' or 'Win' as a win, 'LOF' or 'Lose' as a loss, and 'Draw' as a draw
      if (!self.results) return undefined
      const results = Array.from(self.results.results).sort((a: any, b: any) => a.date.localeCompare(b.date)) as any
      let streak = 0
      let lastResult = undefined
      for (const result of results) {
        if (result.outcome === 'WOF' || result.outcome === 'Win') {
          if (lastResult === 'WOF' || lastResult === 'Win') {
            streak++
          } else {
            streak = 1
          }
        } else if (result.outcome === 'LOF' || result.outcome === 'Lose') {
          if (lastResult === 'LOF' || lastResult === 'Lose') {
            streak--
          } else {
            streak = -1
          }
        } else if (result.outcome === 'Draw') {
          streak = 0
        }
        lastResult = result.outcome
      }
      return streak
    }

    const getLadderPosition = () => {
      if (!self.ladder) return undefined
      const position = self.ladder.ladder.find((team: any) => team.teamId === self.team.teamId)?.position

      // Convert positions to strings with the correct suffix (e.g. 1st, 2nd, 3rd)
      if (!position) return undefined
      if (position === 1) return `${position}st`
      if (position === 2) return `${position}nd`
      if (position === 3) return `${position}rd`
      return `${position}th`
    }

    const getNextGame = (): IGetTeamFixtureReturnValue['matches'][0] | undefined => {
      if (!self.fixture || !self.fixture.matches?.length) return undefined
      // The time of each fixture needs to be checked as the fixture is not updated immediately after the game is played
      // NOTE: The fixture date is in local time but no timezone modifier is included in the string

      return self.fixture.matches.find((game: any) => moment.tz(game.date, 'Australia/Melbourne').isAfter(moment().tz('Australia/Melbourne'))) as IGetTeamFixtureReturnValue['matches'][0]
    }

    const getFutureGames = (): IGetTeamFixtureReturnValue['matches'] => {
      if (!self.fixture || !self.fixture.matches?.length) return []
      // The time of each fixture needs to be checked as the fixture is not updated immediately after the game is played
      // NOTE: The fixture date is in local time but no timezone modifier is included in the string

      return self.fixture.matches.filter((game: any) => moment.tz(game.date, 'Australia/Melbourne').isAfter(moment().tz('Australia/Melbourne'))) as IGetTeamFixtureReturnValue['matches']
    }

    const sortFixture = (fixture: IGetTeamFixtureReturnValue) => {
      return {
        ...fixture,
        matches: fixture.matches.sort((a, b) => a.date.localeCompare(b.date))
      }
    }

    const getRecordAgainstTeam = (opponentName: string) => {
      if (!self.results) return undefined
      const results = self.results.results.filter((result: any) => result.opponentName === opponentName)
      if (results.length === 0) return undefined

      console.log(results)
      const wins = results.filter((result: any) => result.outcome === 'WOF' || result.outcome === 'Win')
      const losses = results.filter((result: any) => result.outcome === 'LOF' || result.outcome === 'Lose')
      const draws = results.filter((result: any) => result.outcome === 'Draw')
      const scoreFor = results.reduce((acc: number, result: any) => acc + result.score, 0)
      const scoreAgainst = results.reduce((acc: number, result: any) => acc + result.opponentScore, 0)
      const averageWinDifference = wins.reduce((acc: number, result: any) => acc + (result.score - result.opponentScore), 0) / wins.length
      const averageLossDifference = losses.reduce((acc: number, result: any) => acc + (result.score - result.opponentScore), 0) / losses.length
      return {
        wins: wins.length,
        losses: losses.length,
        draws: draws.length,
        scoreAgainst,
        scoreFor,
        averageWinDifference,
        averageLossDifference
      }
    }

    const getFixturedEvents = () => {
      if (!self.fixture) return []
      return self.fixture.matches.map((match: any) => {
        const start = moment.tz(match.date, 'Australia/Melbourne');
        const end = start.clone().add(30, 'minutes');

        return {
          title: `${self.team.teamName} vs ${match.opponentName}`,
          description: `Round: ${match.round}`,
          start: [start.year(), start.month() + 1, start.date(), start.hours(), start.minutes()],
          end: [end.year(), end.month() + 1, end.date(), end.hours(), end.minutes()],
        };
      })
    }

    const getSavedCalendarEvents = () => {
      const calendarEvents = localStorage.getItem(`calendarEvents${self.team.teamId}`)
      if (!calendarEvents) return []
      return JSON.parse(calendarEvents)
    }

    const saveAddedCalendarEvents = (events: EventAttributes[]) => {
      const calendarEvents = self.getSavedCalendarEvents()
      calendarEvents.push(...events)
      localStorage.setItem(`calendarEvents${self.team.teamId}`, JSON.stringify(calendarEvents))
    }

    const getNewCalendarFixtures = () => {
      // Get all fixtured events that are not already saved in the calendar
      const fixturedEvents = self.getFixturedEvents()
      const savedCalendarEvents = self.getSavedCalendarEvents()
      return fixturedEvents.filter((fixturedEvent: any) => !savedCalendarEvents.find((savedCalendarEvent: EventAttributes) => {
        return savedCalendarEvent.title === fixturedEvent.title &&
          savedCalendarEvent.start[0] === fixturedEvent.start[0] &&
          savedCalendarEvent.start[1] === fixturedEvent.start[1] &&
          savedCalendarEvent.start[2] === fixturedEvent.start[2] &&
          savedCalendarEvent.start[3] === fixturedEvent.start[3] &&
          savedCalendarEvent.start[4] === fixturedEvent.start[4]
      }))
    }

    return {
      fetchLadder,
      fetchResults,
      fetchFixture,
      getStreak,
      getLadderPosition,
      getNextGame,
      getFutureGames,
      getRecordAgainstTeam,
      getFixturedEvents,
      getSavedCalendarEvents,
      saveAddedCalendarEvents,
      getNewCalendarFixtures,
      sortFixture,
    }
  })


export default TeamStore

export type TeamStoreType = Instance<typeof TeamStore>
export type TeamStoreSnapshotType = SnapshotOut<typeof TeamStore>
